.bookingOptionsContainer {
  padding: 20px 0;
}

.bookingOptionsContainer h1 {
  font-size: 2em;
  font-weight: 600;
  margin-top: 0;
}

.optionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.optionsContent h1 {
  font-size: 1rem;
  color: #2e2e2e;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.optionsContent h2 {
  font-size: 1rem;
  color: #2e2e2e;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.optionsContent a {
  font-size: 1rem;
  color: #2e2e2e;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
