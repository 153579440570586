
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: #f0f2f5; */
  height: 100vh;
  margin: 0;
  color: #333;
}
a {
  text-decoration: none;
  color: #007BFF; /* Bootstrap primary blue */
  font-weight: bold;
  cursor: pointer;
}

/* Hover and focus states for interactivity */
a:hover, a:focus {
  color: #0056b3; /* Darker blue shade for hover state */
  text-decoration: underline;
}

/* Specific styles for "Forgot password" link */
.forgot-password {
  font-size: 0.9rem; /* Slightly smaller font size */
  color: #6c757d; /* Bootstrap's secondary text color */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* *{
  border: 1px solid red;
} */