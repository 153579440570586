.topTierContainer {
  padding: 20px 0;
}

.topTierContainerH1 {
  font-size: 2em;
  font-weight: 600;
  margin-top: 0;
}

.topTierContentContainer {
  padding: 20px;
  border: 2px solid #dedede;
  border-radius: 15px;
  cursor: pointer;
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topTierContentContainerHover {
  border: 2px solid #000;
  background-color: rgb(240, 240, 240);
}

.topTierIconContentContainerH2 {
  margin: 0;
  font-size: 1.2em;
  font-weight: 700;
}

.topTierIconContentContainerP {
  font-size: 1em;
  font-weight: 300;
}
