.container {
  padding: 20px;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.recipientsSection {
  margin-bottom: 20px;
}

.recipientsSection h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

.selectAllButton {
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.selectAllButton:hover {
  background-color: #0056b3;
}

.messageSection {
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 1rem;
}

.sendButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.sendButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.sendButton:hover:not(:disabled) {
  background-color: #218838;
}
