/* Styles for the AdminPanel */
.adminPanel {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.adminPanel h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.meterTable {
  width: 100%;
  border-collapse: collapse;
}

.meterTable th,
.meterTable td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.meterTable th {
  background-color: #007bff;
  color: white;
}

.actionsButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.actionsButton:hover {
  background-color: #218838;
}

.toggleButton {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.toggleButton:hover {
  background-color: #0056b3;
}

.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.dialog h3 {
  margin-top: 0;
}

.dialog label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.dialog input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
}

.dialogActions button {
  margin-left: 10px;
  padding: 8px 12px;
}

.dialogActions .cancelButton {
  background-color: #ccc;
}

.dialogActions .cancelButton:hover {
  background-color: #aaa;
}

.dialogActions .confirmButton {
  background-color: #007bff;
  color: white;
}

.dialogActions .confirmButton:hover {
  background-color: #0056b3;
}

/* Styles for the UserPanel */
.userPanel {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
}

.userPanel h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.userPanel p {
  font-size: 18px;
  color: #555;
  margin: 10px 0;
}

.userPanel p span {
  font-weight: bold;
  color: #333;
}

.userPanel .relayStateOn {
  color: green;
  font-weight: bold;
}

.userPanel .relayStateOff {
  color: red;
  font-weight: bold;
}
.relayButton {
  background-color: #4CAF50; /* Green for ON */
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid black;
}

.relayButton:nth-of-type(2) {
  background-color: #f44336; 
}
