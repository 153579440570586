.accessebilityFeaturesContainer {
  margin: 20px 0;
}

.accessebilityFeaturesContainer h1 {
  font-size: 2rem;
  font-weight: 700;
}

.accessebilityFeaturesContainer h2 {
  font-size: 1em;
  font-weight: 300;
}

.optionsSelectContentMainContainer {
  display: flex;
  margin: 20px 0;
  align-items: center;
}

.checkboxColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30vw;
}

.checkboxColumn label {
  color: gray;
}

.checkboxColumn label:hover {
  color: black;
  cursor: pointer;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.accessibilityFeaturesContainer a {
  font-size: 1rem;
  text-decoration: underline;
  color: black;
  cursor: pointer;
  margin-bottom: 10px;
}
