.viewAdminWrapper {
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.backButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
}

.backButton:hover {
  background-color: #0056b3;
}

.adminName {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.detailsContainer {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detailsContainer p {
  margin: 10px 0;
  color: #555;
}

.detailsContainer strong {
  color: #333;
}
