.add-guest-container {
  background-color: #f3f8fe;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.page-title {
  color: #007bff;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.guest-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.form-input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.form-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form-button:hover {
  background-color: #0056b3;
}

.guest-list-title {
  color: #007bff;
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.guest-list {
  list-style: none;
  padding: 0;
}

.guest-item {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.guest-item h3 {
  color: #007bff;
}

.edit-button,
.delete-button {
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.edit-button {
  background-color: #ffcc00;
  color: white;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.edit-button:hover {
  background-color: #e6b800;
}

.delete-button:hover {
  background-color: #c82333;
}
