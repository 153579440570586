.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 300px;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardBody {
  position: relative;
  height: 250px; /* This height should match the carousel container */
}

.carouselImageContainer {
  width: 100%;
  height: 250px; /* Ensure container is 250px */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.noImages {
  text-align: center;
  padding: 20px;
  color: #888;
}

.cardHeader {
  padding: 15px 0;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: start;
}

.cardHeader:hover {
  background-color: #e0e0e0;
}

.cardHeader h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.buildingName {
  font-weight: bold;
}

.propertyClassification {
  color: #777;
}

.skeletonCard {
  width: 300px;
  height: 200px;
}

.skeletonHeader {
  height: 50px;
  margin: 10px;
  border-radius: 5px;
}

.skeletonImage {
  height: 150px;
  margin: 10px;
  border-radius: 5px;
}

/* Carousel Customizations */
.carouselContainer .control-arrow {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  border-radius: 50%; /* Circular buttons */
  width: 30px; /* Smaller width */
  height: 30px; /* Smaller height */
  transition: all 0.3s ease;
}

.carouselContainer .control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.carouselContainer .control-arrow::before {
  font-size: 16px; /* Smaller arrow size */
  color: #fff; /* White arrow color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .cardsContainer {
    gap: 10px;
    padding: 10px;
  }

  .card {
    width: 100%;
    max-width: 400px;
  }

  .cardBody {
    height: 200px;
  }

  .cardImage {
    height: 200px;
  }

  .cardHeader h2 {
    font-size: 16px;
  }

  .carouselContainer .control-arrow {
    width: 25px; /* Smaller for mobile */
    height: 25px;
  }

  .carouselContainer .control-arrow::before {
    font-size: 14px; /* Smaller arrow size */
  }
}

@media (max-width: 480px) {
  .cardsContainer {
    gap: 5px;
    padding: 5px;
  }

  .card {
    width: 100%;
    max-width: 100%;
  }

  .cardBody {
    height: 150px;
  }

  .cardImage {
    height: 150px;
  }

  .cardHeader h2 {
    font-size: 14px;
  }

  .carouselContainer .control-arrow {
    width: 20px; /* Even smaller for very small screens */
    height: 20px;
  }

  .carouselContainer .control-arrow::before {
    font-size: 12px; /* Even smaller arrow size */
  }
}
