.financialsWrapper {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.pageTitle {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}
.adminSection {
  margin-bottom: 30px;
}

.adminBoxContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.adminBox {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
}

.adminBox:hover {
  transform: scale(1.05);
}

.adminBox.active {
  border-left: 4px solid green;
}

.adminBox.suspended {
  border-left: 4px solid yellow;
}

.adminBox.blocked {
  border-left: 4px solid red;
}

.summarySection {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.summaryBox {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.summaryBox h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.revenue,
.expenses,
.netProfit {
  font-size: 1.25rem;
  font-weight: bold;
}

.chartSection {
  margin-bottom: 30px;
}

.chartSection h2 {
  font-size: 1.75rem;
  margin-bottom: 20px;
}

.pieChartContainer {
  max-width: 50vh; /* Max width of 50vh */
  margin: 0 auto;
  height: auto;
}

.trendChartContainer {
  max-width: 50vh; /* Max width of 50vh */
  margin: 0 auto;
  height: auto;
}

@media (max-width: 1200px) {
  .summaryBox {
    width: 45%;
  }

  .chartSection h2 {
    font-size: 1.5rem;
  }

  .pieChartContainer,
  .trendChartContainer {
    max-width: 80vw; /* Resize charts for medium screens */
  }
}

@media (max-width: 768px) {
  .summaryBox {
    width: 100%;
  }

  .adminBox {
    width: 100%;
  }

  .chartSection h2 {
    font-size: 1.25rem;
  }

  .financialsWrapper {
    padding: 15px;
  }

  .pieChartContainer,
  .trendChartContainer {
    max-width: 90vw; /* Resize further for smaller screens */
  }
}

@media (max-width: 480px) {
  .pageTitle {
    font-size: 1.5rem;
  }

  .summaryBox h2 {
    font-size: 1.25rem;
  }

  .revenue,
  .expenses,
  .netProfit {
    font-size: 1.1rem;
  }

  .chartSection h2 {
    font-size: 1.1rem;
  }

  .adminBoxContainer {
    flex-direction: column;
  }

  .pieChartContainer,
  .trendChartContainer {
    max-width: 100vw; /* Allow full width on very small screens */
  }
}
