.serviceAnimalsMainContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceAnimalsContainer {
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  animation: appear 0.5s ease-in-out forwards;
  padding: 2.77vh 2.08vw;
}

.closeBtnContainer {
  padding-bottom: 2vh;
}

.closeButton {
  width: 0.8vw;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.serviceAnimalsImage {
  width: 25.83vw;
  height: auto;
  margin-bottom: 20px;
}

.serviceAnimalsContent {
  background: white;
}

.serviceAnimalsContent h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.serviceAnimalsContent p {
  font-size: 16px;
  margin-bottom: 20px;
}

.serviceAnimalsContent a {
  color: black;
  text-decoration: underline;
}
