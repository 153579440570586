.manageAdminsWrapper {
  padding: 20px;
  background-color: #f9f9f9;
}

.header {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.sectionHeader {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 10px;
}

.pendingAdmins {
  margin-bottom: 30px;
}

.pendingList {
  list-style: none;
  padding: 0;
}

.pendingItem {
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionButtons button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}

.approveButton {
  background-color: #28a745;
  color: #fff;
}

.rejectButton {
  background-color: #dc3545;
  color: #fff;
}

.adminBoxes {
  margin-top: 20px;
}

.boxContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.adminBox {
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.adminName {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.manageAdminsWrapper {
  padding: 20px;
  background-color: #f8f9fa;
}

.pageTitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 20px;
  margin: 20px 0 10px;
}

.adminsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.adminBox {
  padding: 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.adminBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.adminBox h3 {
  margin: 0 0 10px;
  color: #007bff;
}

.adminBox p {
  margin: 5px 0;
  color: #333;
}

.requestList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.requestItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.requestItem:last-child {
  border-bottom: none;
}

.requestDetails {
  flex: 1;
}

.requestName {
  font-weight: bold;
  color: #333;
}

.requestEmail {
  display: block;
  font-size: 0.9rem;
  color: #777;
}

.requestActions button {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.acceptButton {
  background-color: #28a745;
  color: #fff;
}

.acceptButton:hover {
  background-color: #218838;
}

.deleteButton {
  background-color: #dc3545;
  color: #fff;
}

.deleteButton:hover {
  background-color: #c82333;
}

.noRequests {
  color: #777;
  font-size: 1rem;
}
.listingsSection {
  margin-top: 20px;
}

.listingsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listingItem {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.listingItem:last-child {
  border-bottom: none;
}

.approveButton {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.approveButton:hover {
  background-color: #0056b3;
}
.deleteButton {
  margin-left: 15px;
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.deleteButton:hover {
  background-color: #c82333;
}
