/* Container for the entire page */
.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e3f2fd; /* Light blue background */
}

/* Card for feedback form */
.feedbackCard {
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff; /* White background for the card */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #1a237e; /* Navy blue text */
  text-align: center;
}

/* Heading */
.feedbackCard h2 {
  color: #1a237e; /* Navy blue heading */
  font-size: 24px;
  margin-bottom: 10px;
}

/* Paragraph */
.feedbackCard p {
  color: #1a237e; /* Navy blue text */
  font-size: 16px;
  margin-bottom: 20px;
}

/* Feedback textarea */
.textArea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #1a237e; /* Navy blue border */
  border-radius: 4px;
  background-color: #ffffff; /* White background for textarea */
  color: #1a237e; /* Navy blue text */
  font-size: 14px;
}

.textArea:focus {
  outline: 2px solid #42a5f5; /* Blue focus outline */
}

/* Submit button */
.submitButton {
  padding: 10px 20px;
  background-color: #1a237e; /* Navy blue button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submitButton:hover {
  background-color: #3949ab; /* Slightly lighter navy blue on hover */
}
