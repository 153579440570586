.properties-cards-container {
  text-align: center;
}

.cards {
  height: 100vh;
}

.properties-cards-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.properties-cards-button {
  text-align: center;
  cursor: pointer;
  padding: 14px 24px;
  font-weight: 600;
  font-size: 16px;
  color: white;
  background: black;
  width: auto;
  margin: 0;
  border: none;
  touch-action: manipulation;
  border-radius: 10px;
  box-shadow: var(--dls_button_box-shadow);
  transition: box-shadow 0.2s
      var(--linaria-theme_motion-standard-curve-animation-timing-function),
    transform 0.1s
      var(--linaria-theme_motion-standard-curve-animation-timing-function);
}
