.adminDashboardMain {
  min-height: 40vh;
  background: #fff;
  padding: 80px 80px;
  display: flex;
  justify-content: space-between;
}

.textPrimary {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.textSecondary {
  color: #505050;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textNormal {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
}

.textDangerContainer {
  gap: 4.73vw;
  display: flex;
  align-items: center;
}

.textDanger {
  color: #ac0000;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.2px;
}

.textLinkNormal {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
  text-decoration: underline;
  cursor: pointer;
}

.adminDashboardButton {
  width: 255px;
  height: 62px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #fff;
  cursor: pointer;
  border: 1px solid rgba(171, 171, 171, 0.5);
}

.adminDashboardButton:hover {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.adminDashboardButtonText {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
