.dialogMainContainer {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  width: 426px;
  height: 342px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 100px;
  right: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.dialogOptions {
  width: 100%;
}

.loginText {
  font-weight: 700;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 20px;
}

.optionsBreak {
  width: 100%;
  height: 1px;
  background: #ABABAB;
  margin: 20px 0;
}

.dialogMenu {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 20px;
}

.dialogMenu:last-child {
  margin-bottom: 0;
}

h3 {
  padding: 5px;
  margin: 0;
}
