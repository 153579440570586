.facilitiesMainContainer {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  column-gap: 20px;
  overflow: hidden;
}

.swiperArrowLeft {
  position: absolute;
  top: 28px;
  left: 7px;
}

.swiperArrowLeft,
.swiperArrowRight {
  transform: translateY(-50%);
  cursor: pointer;
}

.facilitiesSliderContainer {
  width: 70vw;
  column-gap: 10px;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.facilityItem {
  margin: 0 30px;
  padding-bottom: 7px;
  border-bottom: 2px solid white;
}

.facilityItem:hover {
  cursor: pointer;
  border-bottom: 2px solid rgb(207, 207, 207);
}

.facilityItemContainer {
  overflow-x: hidden;
  max-width: 80vw;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.swiperArrowRight {
  position: absolute;
  top: 28px;
  right: 0;
}

.iconTextBox {
  text-align: center;
  white-space: nowrap;
  border-bottom: 2px solid #fff;
}

.iconTextBox:hover path {
  fill: #000;
}

.iconTextBox svg {
  width: 1.875vw;
  height: 2.875vh;
}

.iconsText {
  color: #6a6a6a;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin: 3px 0 0 0;
}

.filterContainer {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #ababab;
  background: #fff;
  cursor: pointer;
}

.filterContainer:hover {
  background-color: rgb(245, 245, 245);
  border: 1px solid #000;
}

.filterText {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
}

.taxContainer {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #ababab;
  background: #fff;
  cursor: pointer;
  padding: 12px 10px;
}

.taxContainer:hover {
  background-color: rgb(245, 245, 245);
  border: 1px solid #000;
}

.taxContainer h1 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}
