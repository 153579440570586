.searchBarMainContainer {
  height: 98px;
  border-bottom: 1px solid rgba(171, 171, 171, 0.5);
  background: rgba(0, 0, 0, 0);
  mix-blend-mode: multiply;
}

.formContainer {
  display: flex;
  align-items: center;
  max-width: 958px;
  margin: auto;
  height: 78px;
  flex-shrink: 0;
  border-radius: 45px;
  border: 1px solid rgba(171, 171, 171, 0.5);
  box-shadow: 0px 0px 7px 4px rgba(171, 171, 171, 0.5);
}

.selectCityField {
  width: 248px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 45px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  text-align: center;
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
}

.citiesNames {
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
}

.breakline {
  width: 1px;
  height: 32px;
  background: rgba(171, 171, 171, 0.5);
}

.fieldIconContainer {
  width: 610px;
  margin-left: 80px;
  display: flex;
  align-items: center;
}

.locationField {
  border: none !important;
}

.searchIcon {
  cursor: pointer;
}
