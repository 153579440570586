.statisticsPage {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.pageTitle {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.statsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.statCard {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.statCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.statTitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
}

.statValue {
  font-size: 1.5rem;
  font-weight: bold;
  color: #222;
}

.trend-up .statValue {
  color: #28a745;
}

.trend-down .statValue {
  color: #dc3545;
}

.trend-neutral .statValue {
  color: #6c757d;
}
