/* General Form Styling */
.formContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.floor {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #fff;
  background-color: #02243a;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.room {
  margin-left: 20px; /* Indent rooms under floors */
  background-color: #2c3e50;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.seat {
  margin-left: 40px; /* Indent seats under rooms */
  background-color: #8e44ad;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #2d2c50;
}

.label {
  display: block;
  font-size: 1rem;
  margin-bottom: 8px;
  color: #34495e;
  font-weight: bold;
}

.input, .select, .checkbox {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.checkbox {
  width: auto;
  margin-right: 10px;
}

.input:focus, .select:focus {
  border-color: #3498db;
  outline: none;
}

.select {
  cursor: pointer;
  appearance: none;
  background: url('data:image/svg+xml;base64,PCEtLUFST1ctLSBlbXB0eSBkaXZpZGVyIC0tPg==') no-repeat right 10px center;
  background-size: 10px;
}

.error {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: -15px;
  margin-bottom: 10px;
}

.button {
  padding: 10px 15px;
  background-color: #3498db;
  border: none;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #2980b9;
}

.button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.submitButton {
  width: 100%;
  margin-top: 20px;
}

/* Step Navigation Buttons */
.stepNavigation {
  display: flex;
  justify-content: space-between;
}

/* Additional Styles for Hierarchy Visualization */
.hierarchyContainer {
  border-left: 3px solid #3498db;
  padding-left: 20px;
  margin-bottom: 20px;
}

.hierarchyLevel {
  margin-bottom: 15px;
}

.hierarchyLevel .label {
  margin-left: 20px;
}

.roomField {
  margin-left: 40px;
}

.seatField {
  margin-left: 60px;
}
.imagePreview {
  margin-top: 10px;
}
.imagePreview img {
  border: 1px solid #ccc;
  border-radius: 4px;
  object-fit: cover;
}
