.redirectMainContainer {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; 
}

.redirectContentContainer {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  animation: appear 0.5s ease-in-out forwards;
  padding: 2.77vh 2.08vw;
}

.iconRotate {
  animation: rotate 3s infinite linear; /* Adjust the duration and easing as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
