/* Footer.css */
.footer {
  background: #f1f1f1;
  color: black;
  padding: 20px 40px 0;
  text-align: center;
  /* position:fixed; */
  /* width: 100%; */
  bottom: 0;
  left: 0;
  border-top: 2px solid #ababab;
}

.footerColumns {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.icon-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 8vw;
}
.icon-container svg {
  padding: 3px;
}
.dash {
  border-top: 2px solid #ababab;
  width: 100%;
  margin: 40px auto;
}
.footerColumn {
  flex: 1;
  margin: 0 10px;
  text-align: left;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.footer-column.lower-row-first {
  flex: 1 0 60%;
}
.footerColumn h2 {
  color: black;

  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left,
.footer-right {
  flex: 1;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}
