.adminDashboardMain {
  padding: 20px;
  background-color: #f8f9fa;
}

.adminDashboardContent {
  max-width: 1000px;
  margin: 0 auto;
}

.textPrimary {
  color: #007bff;
}

.textSecondary {
  color: #6c757d;
}

.flexbox {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.adminDashboardButton {
  margin-top: 30px;
  background-color: #007bff;
  color: white;
  padding: 15px 25px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.adminDashboardButtonText {
  font-size: 18px;
  font-weight: bold;
}

.adminsList {
  margin-top: 30px;
}

.adminItem {
  padding: 15px;
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.adminItem h3 {
  color: #007bff;
  margin: 0;
}

.adminItem p {
  margin: 5px 0;
}

.adminItem button {
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.adminItem button:hover {
  background-color: #0056b3;
}

.textDangerContainer {
  display: flex;
  align-items: center;
}

.textDanger {
  color: red;
  font-weight: bold;
}

.textNormal {
  color: #333;
}

.textLinkNormal {
  color: #007bff;
  text-decoration: none;
}

.textLinkNormal:hover {
  text-decoration: underline;
}
.dashboardWrapper {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sidebarButton {
  padding: 10px;
  margin: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 16px;
}

.sidebarButton:hover {
  background-color: #0056b3;
}

.adminDashboardContent {
  flex-grow: 1;
  padding: 20px;
}

.adminItem {
  margin-bottom: 20px;
  color: inherit;
}

.adminDashboardButton {
  padding: 15px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.adminDashboardButtonText {
  font-size: 18px;
}

.summaryTable{
  width: 500px;
}

table, th, td{
  border: 1px solid black;
}

tfoot{
  font-weight: 700;
}