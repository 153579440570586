.facilitiesDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.facilitiesDialogContainer {
  background: #fff;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  animation: appear 0.5s ease-in-out forwards;
}

.facilitiesDialogHeaderContainer {
    border-bottom: 1px solid #ababab;
    padding: 2.77vh 2.08vw;
}

.dialogHeaderContent {
  display: flex;
  justify-content: center;
  position: relative;
}

.facilitiesDialogCloseIcon {
  position: absolute;
  top: 0.6vh;
  left: 0.2vw;
  width: 0.981vw;
  height: 2vh;
  flex-shrink: 0;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.dialogHeaderTitle {
  position: relative;
  color: #000;
  font-family: Inter;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
  text-align: center;
}

.facilitiesDialogContent {
  padding: 20px 2.18vw;
  max-height: 600px;
  overflow: auto;
}

.facilitiesDialogContent h1 {
  color: #000;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.facilitiesDialogContent p {
  color: #000;
  font-family: Inter;
  font-size: 0.8vw;
  font-weight: 300;
  margin: 8px 0 0 0;
}

.facilitiesDialogFooterContainer {
  border-top: 1px solid #ababab;
  padding: 1.8vh 1.8vw;
}

.facilitiesDialogFooterContent {
  display: flex;
  justify-content: space-between;
}

.facilitiesDialogFooterContent button {
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
}

.facilitiesDialogFooterContent button:hover{
  background: #f3f3f3;
}

.facilitiesDialogFooterLinkBtn{
  cursor: pointer;
  background: rgb(42, 42, 42);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facilitiesDialogFooterLinkBtn:hover{
  background: #000;
}

.facilitiesDialogFooterLinkBtn a{
  color: #fff;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 18px 24px;
  text-align: center;
  text-decoration: none;
}