.progressBarContainer {
  position: sticky;
  top: 0;
  height: 6px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
  z-index: 1000; /* Ensure it stays on top */
}

.progressBar {
  height: 100%;
  background-color: #007bff;
  width: 0;
  transition: width 0.3s;
}
