:root {
  --more-options-main-contianer-width: 39.27vw;
  --more-options-main-contianer-height: 80.55vh;
}

.more-options-main-container {
  position: absolute;
  z-index: 1000;
  top: calc(50vh - calc(var(--more-options-main-contianer-height) / 2));
  left: calc(50vw - calc(var(--more-options-main-contianer-width) / 2));
  width: var(--more-options-main-contianer-width);
  height: var(--more-options-main-contianer-height);
  scale: 1.1;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  animation: appear 0.5s ease-in-out forwards;
}

.more-options-title-svg {
  padding: 20px 0;
}

.more-options-title {
  position: relative;
  color: #000;
  font-family: Inter;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.backword-svg {
  position: absolute;
  top: 3vh;
  left: 2vw;
  width: 0.981vw;
  height: 2.88vh;
  flex-shrink: 0;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.backword-svg:hover{
  transform: scale(1.1);
  fill: #000;
}

.break-line-more-options-dialog {
  width: 100%;
  height: 1px;
  background: #ababab;
}

.text-number {
  color: #000;
  font-family: Inter;
  font-size: 1.014vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notify-text {
  color: #000;
  font-family: Inter;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.selection-main-container {
  margin: 5vh 0;
}

.text-select-main-container {
  padding: 20px 41px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.icon-text-select-container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.icon {
  margin-right: 30px;
  width: 1.82vw;
}

.select-text-container {
  flex-grow: 5;
}

.select-text {
  color: #000;
  font-family: Inter;
  font-size: 1.354vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.select-inform-text {
  color: #000;
  font-family: Inter;
  font-size: 0.83vw;
  font-weight: 200;
  margin: 0;
}

.select-container-break-line {
  background: #ababab;
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
}

.more-option-container-btn {
  margin-top: 4.85vh;
  width: 100%;
  height: 7.314vh;
  flex-shrink: 0;
  border-radius: 15px;
  font-family: Inter;
  font-size: 1.875vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.more-option-container-btn.active {
  background-color: #004acc;
  color: white;
  border: none;
}

.more-option-container-btn.inactive {
  background: #000;
  color: darkgray;
  cursor: not-allowed;
}

.input-radio-field {
  width: 2.08vw;
  height: 3.7vh;
}

.input-radio-field:checked {
  width: 2.08vw;
  height: 3.7vh;
  flex-shrink: 0;
  stroke-width: 10px;
  stroke: #000;
  background-color: #ccc;
  border-color: #000;
}

.error-message {
  color: red;
  margin-top: 10px;
}