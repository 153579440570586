.socialSigninMain {
    border: 1px solid black;
    padding: 6px 15px;
    margin: 10px 35px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

.socialSigninMainImgIcon {
    position: absolute;
    top: 1.48vh;
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin: 0 10px;
}

.socialSigninMainH1 {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
}

.socialSigninMainHover {
    background-color: rgb(240, 240, 240);
    transition: 0.1s ease-in-out;
}
