/* src/pages/notFound/NotFound.css */
.notFound {
  text-align: center;
  margin: 116px 0;
}

.notFound h1 {
  font-size: 48px;
  margin-bottom: 0;
}

.notFound p {
  font-size: 18px;
  margin-bottom: 60px;
}

.notFoundLink {
  background-color: #fff;
  border-radius: 30px;
  text-decoration: none;
  color: black;
  letter-spacing: 0.3px;
  cursor: pointer;
  padding: 15px 25px;
  font-size: 1rem;
  border: 1px solid gainsboro;
}

.notFoundLink:hover {
  text-decoration: none;
  background-color: rgb(60, 60, 222);
  color: white;
  border: none;
  transition: background-color 0.3s, transform 0.3s;
}
