.navbarMainContainer {
  width: 100%;
}

.navbarContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 15px 15px 0 0;
  background: #fff;
  height: 98px;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

@media (max-width: 768px) {
  .navbarContentContainer {
    height: 50px;
  }
}

.logoMainContainer {
  width: 190px;
  height: 46.327px;
  cursor: pointer;
}

.navbarTitleSvgsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  width: 60vw;
}

.rentpalLogo {
}

@media (max-width: 768px) {
  .rentpalLogo {
    width: 100px;
  }
}

.navbarTitle {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
  width: auto;
}

@media (max-width: 768px) {
  .navbarTitle {
    display: none;
  }
}

.navbarSvgsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid #ababab;
  border-radius: 50px;
  padding: 10px 20px;
  background: #f9f9f9;
  margin-left: auto;
}

.navbarSvgsContainer:hover{
  box-shadow: 0px 3px 5px 0px rgba(190, 190, 190, 0.6);
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbarSvgsContainer {
    padding: 5px 10px;
    gap: 10px;
  }
}

.dashboardContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menuIcon {
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

@media (max-width: 768px) {
  .menuIcon {
    width: 17px;
  }
}

.menuIcon:hover {
  transform: scale(1.1);
  fill: #000;
}

.navbarIcon2 {
  background-color: #6a6a6a;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

@media (max-width: 768px) {
  .navbarIcon2 {
    width: 15px;
    height: 15px;
  }
}

.navbarIcon2:hover {
  transform: scale(1.1);
  background-color: #000;
}

.dialogMainContainer {
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
  width: 426px;
  height: 342px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 100px;
  right: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.dialogOptions {
  width: 100%;
}

.loginText {
  font-weight: 700;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 20px;
  transition: color 0.3s;
}

.loginText:hover {
  color: #555;
}

.optionsBreak {
  width: 100%;
  height: 1px;
  background: #ababab;
  margin: 20px 0;
}

.dialogMenu {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 20px;
  transition: color 0.15s ease-in-out, padding 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
}

.dialogMenu:hover {
  background-color: #c0c0c0;
  color: white;
}

.dialogMenu:last-child {
  margin-bottom: 0;
}
