.crashReportsPage {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.pageTitle {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.crashTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.crashTable thead {
  background-color: #007bff;
  color: #fff;
}

.crashTable th,
.crashTable td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.crashTable th {
  font-weight: bold;
}

.tableRow:hover {
  background-color: #f1f1f1;
}

.status {
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}

.status-resolved {
  background-color: #28a745;
  color: #fff;
}

.status-pending {
  background-color: #ffc107;
  color: #fff;
}

.status-investigating {
  background-color: #dc3545;
  color: #fff;
}
