.showAllPhotosContainer {
  /* padding: 20px; */
  text-align: center;
}

.btnLinkContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px !important;
}

.backButton {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.backButton:hover {
  background-color: antiquewhite;
}

.backButton:active{
  box-shadow: 2px #666;
  transform: translateY(1px);
}

.showAllPhotosContent {
  padding: 0 80px;
}

.photosGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.photo {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}
