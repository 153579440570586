.container {
  padding: 20px;
  background-color: #001f3f; /* Navy blue background */
  color: white;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.addButton {
  background-color: #007bff; /* Light blue for buttons */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.listingsContainer {
  margin-top: 20px;
}

.listings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  list-style-type: none;
  padding: 0;
}

.listingItem {
  background-color: white; /* White background for listing boxes */
  color: #001f3f; /* Navy blue text */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: calc(33.333% - 20px); /* Three boxes per row with gaps */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.listingItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.listingTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.listingData {
  font-size: 14px;
  margin: 5px 0;
}

.noListings {
  text-align: center;
  font-size: 16px;
  color: #f0f0f0;
}
