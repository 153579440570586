/* CSS for AddProperty component */

/* Form container */
.form-container {
    max-width: 600px;
    margin: 20px auto; /* Added margin for better centering */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Added overflow-y for vertical scrolling */
    max-height: calc(100vh - 40px); /* Limit height to viewport height minus margins */
  }
  
  /* Fieldset and legend */
  fieldset {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
  }
  
  legend {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Labels and form controls */
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  input[type="text"],
  input[type="number"],
  select,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M7.406 9.586L12 14.172l4.594-4.586L18 11l-6 6-6-6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 18px;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  /* Checkboxes */
  .checkbox-container {
    display: inline-flex; 
    align-items: justify; 
    margin-right: 10px; 
  }
  
  .checkbox-label {
    margin-left: 5px; 
  }
  /* File input */
  input[type="file"] {
    margin-top: 5px;
  }
  
  /* Submit button */
  button[type="submit"] {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .form-container {
      max-width: 100%;
      margin: 10px auto; /* Adjusted margin for smaller screens */
    }
  }
/* addProperty.css */

/* Style the image container */
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between images */
}

/* Style each image */
.image-container img {
  width: 100%; /* Make images responsive */
  max-width: 200px; /* Limit width to 200px */
  max-height: 250px; /* Limit height to 250px */
  object-fit: cover; /* Maintain aspect ratio and cover container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: transform 0.3s ease-in-out; /* Smooth hover effect */
}

/* Hover effect - Scale up the image */
.image-container img:hover {
  transform: scale(1.05);
}
/* addProperty.css */

/* Style the image container */
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between images */
}

/* Style each image item */
.image-item {
  position: relative;
}

/* Style the remove button */
.image-item button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

/* Hover effect for remove button */
.image-item button:hover {
  background-color: rgba(255, 0, 0, 1);
}
