.propertyTypeMainContainer {
  padding: 20px 0;
}

.propertyTypeMainContainer h1 {
  font-size: 2em;
  font-weight: 600;
  margin-top: 0;
}

.propertyOptionsContainer {
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;
}

.propertyIconContentContainer {
  padding: 20px;
  border: 2px solid #dedede;
  border-radius: 15px;
  width: 9vw;
}

.propertyIconContentContainer:hover {
  border: 2px solid #000;
  background-color: rgb(240, 240, 240);
}

.propertyIconContentContainer h2 {
  font-size: 1.1em;
  margin-bottom: 0;
  margin-top: 25px;
  font-weight: 700;
}
