.placeTypeMainContainer {
  margin: 20px 0;
}

.placeTypeMainContainer h1 {
  font-size: 2rem;
  font-weight: 600;
}

.placeTypeButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.buttonsTypeContainer {
  display: flex;
  border-radius: 20px;
}

.buttonType {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #000000;
  padding: 20px 70px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.buttonType:not(:last-child) {
  border-right: none;
}

.buttonType.selected {
  background-color: #000000;
  color: #ffffff;
}

.buttonType.selected h1 {
  font-size: 1rem;
  font-weight: 600;
}
