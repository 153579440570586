.paymentsContainer {
  background-color: #f5f8ff;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pageTitle {
  text-align: center;
  font-size: 2rem;
  color: #2d70ff;
  margin-bottom: 30px;
}

.paymentMethodSection {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.paymentMethodSection h2 {
  font-size: 1.8rem;
  color: #2d70ff;
}

.paymentMethodSection .formLabel {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #333;
}

.paymentMethodSection .inputField {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.pendingPaymentsSection {
  margin-top: 30px;
}

.pendingPaymentsSection h2 {
  font-size: 1.8rem;
  color: #2d70ff;
  border-bottom: 2px solid #2d70ff;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.paymentTable {
  width: 100%;
  border-collapse: collapse;
}

.paymentTable th,
.paymentTable td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.paymentTable th {
  background-color: #2d70ff;
  color: white;
}

.paymentTable td {
  background-color: #fff;
}

.paymentTable input[type="checkbox"] {
  margin: 0;
}

.paidStatus {
  color: green;
  font-weight: bold;
}

.pendingStatus {
  color: orange;
  font-weight: bold;
}

.lateFee {
  color: red;
  font-weight: bold;
}

.totalAmount {
  color: #2d70ff;
  font-weight: bold;
}
