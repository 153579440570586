.basicBox {
  padding: 30px 20px;
  border-radius: 15px;
  border: 1px solid rgba(171, 171, 171, 0.5);
  background: #fff;
  width: 18.67vw;
  flex-shrink: 0;
  cursor: pointer;
}

.basicBox:hover {
  border: 2px solid rgba(0, 0, 0, 0.5);
  /* box-shadow: 2px 2px rgb(225, 225, 225); */
  /* transition: 0.2s ease-in-out; */
}
