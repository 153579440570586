/* AutomatedAdverts.module.css */
.automatedAdvertsWrapper {
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 80vw;
}
.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.pageTitle {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
}

.section {
  margin-bottom: 30px;
}

.messageSection,
.recipientsSection {
  margin-bottom: 25px;
}

textarea {
  width: 100%;
  height: 120px;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
}

select {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
}

.automatedAdvertsButton {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
}

.automatedAdvertsButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  .pageTitle {
    font-size: 1.8rem;
    margin-bottom: 25px;
  }

  .section {
    margin-bottom: 20px;
  }

  .messageSection,
  .recipientsSection {
    margin-bottom: 20px;
  }

  textarea {
    height: 100px;
    padding: 12px;
  }

  select {
    font-size: 0.95rem;
    padding: 10px;
  }

  .automatedAdvertsButton {
    padding: 12px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .pageTitle {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .section {
    margin-bottom: 15px;
  }

  textarea {
    height: 90px;
    font-size: 0.95rem;
    padding: 10px;
  }

  select {
    font-size: 0.9rem;
    padding: 8px;
  }

  .automatedAdvertsButton {
    font-size: 0.95rem;
    padding: 10px 15px;
  }
}
