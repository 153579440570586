:root {
  --nmber-confirm-container-width: 37.3vw;
  --nmber-confirm-container-height: 41vh;
}

.nmbr-cnfrm-container {
  position: absolute;
  z-index: 1000;
  top: calc(50vh - calc(var(--nmber-confirm-container-height) / 2));
  left: calc(50vw - calc(var(--nmber-confirm-container-width) / 2));
  width: var(--nmber-confirm-container-width);
  height: var(--nmber-confirm-container-height);
  scale: 1.1;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  animation: appear 0.5s ease-in-out forwards;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
}

.nmbr-cnfrm-title-svg {
  padding: 20px 0;
}

.nmbr-cnfrm-title {
  position: relative;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.backword-svg {
  position: absolute;
  top: 3vh;
  left: 2vw;
  width: 18.838px;
  height: 31.164px;
  flex-shrink: 0;
  fill: #000;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.backword-svg:hover{
  transform: scale(1.1);
  fill: #000;
}

.field-text-container {
  padding: 30px 40px;
  
}

.field-text {
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.code-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17em;
  height: 7.31vh;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px solid #ababab;
  background: #fff;
}

.input-field-code-cnfrm {
  position: relative;
  width: 10vw;
  height: 5vh;
  border: none;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 25px;
  letter-spacing: 22px;
}

.input-field-dashes-container {
  position: absolute;
  display: inline-flex;
  align-items: flex-start;
  gap: 35px;
  width: 200px;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

.dashes {
  width: 0.7vw;
  height: 0.09vh;
  background: #000;
}

.link-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.38vh 2.08vw;
}

.option-link {
  color: #000;
  font-family: Inter;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.nmbr-cnfrm-btn {
  width: 11.19vw;
  height: 7.31vh;
  flex-shrink: 0;
  border-radius: 15px;
  background: #004acc;
  border: 0;
  color: #fff;
  font-family: Inter;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
}

.break-line-nmbr-cnfrm-dialog {
  width: var(--nmber-confirm-container-width);
  height: 1px;
  background: #ababab;
}
