.viewPropertyMainContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewPropertyContentContainer{
  width: 1200px;
}

.viewPropertyMainContainer a {
  color: black;
  text-decoration: underline;
}

.propertyHeaderContent{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* General container for images */
.viewPropertyImagesContainer {
  display: flex;
  gap: 10px;
  /* width: 100%; */
  position: relative;
}

/* Left Div for the first image */
.leftDiv {
  width: 50%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden; /* Ensures images do not overflow the borders */
  border: 2px solid transparent; /* Optional: Add a border to the container */
}

/* Right Div for the grid layout */
.rightDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 50%;
  height: auto; /* Ensure height adjusts dynamically */
  gap: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden; 
  border: 2px solid transparent;
}

/* Styles for main image in left div */
.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subImageContainer {
  width: 100%;
  aspect-ratio: 1; /* Ensures a perfect square for each image */
  overflow: hidden; /* Ensures images do not overflow the container */
}

.subImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Button to show all photos */
.viewPropertyImagesBtn {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border: 1px solid black;
  border-radius: 5px;
  background: #fff;
  padding: 7px 15px;
  touch-action: manipulation;
  transition: background-color 0.3s ease;
  font-size: medium;
  width: auto;
  color: black;
  margin: 0;
  cursor: pointer;
}

.viewPropertyImagesBtn:hover {
  background-color: #f0f0f0;
}


.ratingsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 0.5px solid rgba(171, 171, 171, 0.5);
  background: #fff;
  width: 634px;
  height: 100px;
  flex-shrink: 0;
  padding: 0 30px;
  text-align: center;
  cursor: pointer;
}

.ratingsContainer h3 {
  color: black;
}
