.bedsBathroomContainer {
  margin: 20px 0;
}

.bedsBathroomContainer h1 {
  font-size: 2rem;
  font-weight: 600;
}

.bedsBathroomContainer p {
  font-size: 1rem;
  font-weight: 300;
}

.bedsBathroomOptionsContainer {
  margin: 30px 0;
}

.bedsBathroomOptions {
  color: black;
  background-color: white;
  cursor: pointer;
  margin: 10px;
  margin-left: 0;
  padding: 10px 25px;
  border-radius: 20px;
  border: 1px solid rgb(220, 220, 220);
}

.bedsBathroomOptions:hover {
  border: 1px solid black;
}

.bedsBathroomOptions.selected {
  background-color: black;
  color: white;
  border: 1px solid black;
  transition: 0.2s ease-in-out;
}
