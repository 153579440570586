.sign-up-form {
  max-width: 500px;
  margin: 10px auto;
  padding: 20px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
}

.sign-up-form input[type="text"],
.sign-up-form input[type="email"],
.sign-up-form input[type="password"],
.sign-up-form input[type="number"],
.sign-up-form textarea,
.sign-up-form button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid #ababab;
  box-sizing: border-box;
  font-size: 17px;
}

.sign-up-form legend {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sign-up-form label {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}

.sign-up-form input[type="file"] {
  margin-top: 10px;
}

.sign-up-form button {
  border-radius: 15px;
  background: #004acc;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.sign-up-form button:hover {
  background-color: #0056b3;
}



.error-prompts-container {
  position: sticky;
  top: 10%;
  left: 75%;
  z-index: 1000;
  width: 20%;
  height: auto;
  border: 3px solid red;
  border-radius: 5px;
  background-color: pink;
}

.my-button > .disabled-button {
  background-color: #ccc;
  color: #999;
  text-decoration: none;
  border: 10px solid #ec0000;
  cursor: not-allowed;
}
